import React from "react"

// importing components
import Img from "gatsby-image"

// importing assets
import "./product-card.scss"

const ProductCard = props => {
  return (
    <div className="product-card">
      {props.gatsbyImg ? (
        <div className="product-image-wrapper">
          <Img fluid={props.gatsbyImg} />
        </div>
      ) : null}
      <div className="product-title">
        <h3>{props.productTitle}</h3>
      </div>
      <div className="product-description">{props.productDescription}</div>
    </div>
  )
}

ProductCard.defaultProps = {
  productTitle: "Unnamed product",
  productDescription:
    "Lorem ipsum dolor sit amet, consectetur  adipiscing elit, sed diusmod tempor  incididunt ut labore et dolore magna  aliqua. Ut enim ad minim veniam, quis  nostrud exercitation ullamco laboris nisi ut  aliquip ex ea commodo consequat.",
}

export default ProductCard
