import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/utilities/HeroImage/heroImage"
import ProductsCardSection from "../components/products/productsCardSection"

const ProductsPage = () => (
  <Layout>
    <SEO title="Products" />
    <HeroImage
      title="Products"
      hrefForScrollDown="/products/#products-card-section"
    />
    <ProductsCardSection />
  </Layout>
)

export default ProductsPage
