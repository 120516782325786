import React from "react"
import { useStaticQuery, graphql } from "gatsby"

// importing components
import ProductCard from "../utilities/ProductCard/productCard"

// importing assets
import "./products-section.scss"

const ProductsCardSection = () => {
  const data = useStaticQuery(graphql`
    query {
      railwayHingesImage: file(relativePath: { eq: "1_railway_hinges.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      overlayDuckHingesImage: file(
        relativePath: { eq: "2_overlay_duck_hinges.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      buttHingesImage: file(relativePath: { eq: "3_butt_hinges.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wHingesImage: file(relativePath: { eq: "4_w_hinges.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lockWasherHingesImage: file(
        relativePath: { eq: "5_lock_washer_hinges.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lTypeHingesImage: file(relativePath: { eq: "6_L_type_hinges.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      twoOneWtypeHingesImage: file(
        relativePath: { eq: "7_2in1_w_type_hinges.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ZHingesImage: file(relativePath: { eq: "8_z_hinges.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      threeEightTowerboltsImage: file(
        relativePath: { eq: "9_3_8_towerbolts.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      brassAccessoriesImage: file(
        relativePath: { eq: "12_brass_accessories.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      foldingTowelrackRoundImage: file(
        relativePath: { eq: "13_folding towelrack 24 inches round.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      foldingTowelrackSquareImage: file(
        relativePath: { eq: "14_Folding towelrack 24 inches square.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ss304FoldingRackHeavyImage: file(
        relativePath: { eq: "15_ss 304 folding rack heavy.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      camboltsImage: file(relativePath: { eq: "15_cambolts.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lAndKeyLockImage: file(relativePath: { eq: "16_L_and_key_lock.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      parliamentHingesImage: file(
        relativePath: { eq: "17_parliament_hinges.jpeg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      shelfButtonsImage: file(relativePath: { eq: "18_shelf_buttons.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const DataArr = [
    {
      productTitle: "Railway Hinges",
      productDescription:
        "Brass Railway Hinges are designed to provide an effective solution to hanging heavy doors where there is a need for high capacity.",
      gatsbyImg: data.railwayHingesImage.childImageSharp.fluid,
    },
    {
      productTitle: "Overlay Duck Hinges",
      productDescription:
        "These hinges are only used for wardrobes. On the opening of 2 shutters on a single frame, these hinges restrict the doors upon 90̊ and lock them further. Only these hinges are not visible from outside.",
      gatsbyImg: data.overlayDuckHingesImage.childImageSharp.fluid,
    },
    {
      productTitle: "Butt Hinges",
      productDescription:
        "A Butt Hinge is a hinge of two parts, or leaves, joined in the middle and used to hold together two “abutting” objects, such as a door and frame where they are most often used.",
      gatsbyImg: data.buttHingesImage.childImageSharp.fluid,
    },
    {
      productTitle: "W Hinges",
      productDescription:
        "These hinges are mainly used for wardrobes. Even on the opening of 2 shutters on a single frame, these hinges can be used.",
      gatsbyImg: data.wHingesImage.childImageSharp.fluid,
    },
    {
      productTitle: "Lock Washer Hinges",
      productDescription:
        "Brass lock washer Hinges are designed to provide an effective solution to hanging heavy doors where there is a need for high capacity.",
      gatsbyImg: data.lockWasherHingesImage.childImageSharp.fluid,
    },
    {
      productTitle: "L-Type Hinges",
      productDescription:
        "These hinges are mainly used for the kitchen cabinets. The different sizes of two halves allow it to sit on narrow frame and wide shutters. It opens at 180 angle, allow the door to open fully for utilisation of 100% cabinet space.",
      gatsbyImg: data.lTypeHingesImage.childImageSharp.fluid,
    },
    {
      productTitle: "2 in 1 W-type Hinges",
      productDescription:
        "These hinges are used mixing up with the W hinges in order to regain complete opening of the wardrobe shutters. These hinges are only used in cabinets & wardrobes.",
      gatsbyImg: data.twoOneWtypeHingesImage.childImageSharp.fluid,
    },
    {
      productTitle: "Z Hinges",
      productDescription:
        "These hinges are mainly used for the kitchen cabinets. The different sizes of two halves allow it to sit on narrow frame and wide shutters.",
      gatsbyImg: data.ZHingesImage.childImageSharp.fluid,
    },
    {
      productTitle: "Parliament Hinges",
      productDescription:
        "Parliament and Projection hinges are typically used to allow doors to open fully up to 180 degrees, often a pair of doors dividing rooms or pairs of French Doors. These days they are generally made form brass or stainless steel, plain steel parliament hinges are a thing of the past.",
      gatsbyImg: data.parliamentHingesImage.childImageSharp.fluid,
    },
    {
      productTitle: "Towerbolts",
      productDescription:
        "Tower bolts are used at large in both domestic markets for residential purposes and in institutions for commercial purposes. These are used for doors & windows to provide extra security from outside as well as inside.",
      gatsbyImg: data.threeEightTowerboltsImage.childImageSharp.fluid,
    },

    {
      productTitle: "Brass Accessories",
      productDescription:
        "Several fittings like Square Brackets, D Brackets, F Brackets, key holes, and other accessories are used in day to day furniture fittings.",
      gatsbyImg: data.brassAccessoriesImage.childImageSharp.fluid,
    },
    {
      productTitle: "Brass Shelf Buttons",
      productDescription:
        "Brass shelf bats are used to hang or rest the shelves used up in wardrobe or cabinets.",
      gatsbyImg: data.shelfButtonsImage.childImageSharp.fluid,
    },
    {
      productTitle: "Brass LN Key Locks",
      productDescription:
        "LN Key locks are handy to use as a single key can open each and every LN lock present in the set. A hex key, Allen wrench or Allen key, is a simple tool used to drive bolts and screws with hexagonal sockets in their heads.",
      gatsbyImg: data.lAndKeyLockImage.childImageSharp.fluid,
    },
    {
      productTitle: "Brass Cambolts",
      productDescription:
        "Cam or locking screws are 2-part screws where the cam is fitted in one piece and the screw in a piece to be joined to this piece. By slotting the two pieces together and tightening the screw you have a reasonably secure join.",
      gatsbyImg: data.camboltsImage.childImageSharp.fluid,
    },
    {
      productTitle: "Folding Tower Rack (Round)",
      productDescription:
        "THIS IS CUSTOM Lorem ipsum dolor sit amet, consectetur  adipiscing elit, sed diusmod tempor  incididunt ut labore et dolore magna  aliqua.",
      gatsbyImg: data.foldingTowelrackRoundImage.childImageSharp.fluid,
    },
    {
      productTitle: "Folding Tower Rack (Square)",
      productDescription:
        "THIS IS CUSTOM Lorem ipsum dolor sit amet, consectetur  adipiscing elit, sed diusmod tempor  incididunt ut labore et dolore magna  aliqua.",
      gatsbyImg: data.foldingTowelrackSquareImage.childImageSharp.fluid,
    },
    {
      productTitle: "SS 304 Folding Rack Heavy",
      productDescription:
        "THIS IS CUSTOM Lorem ipsum dolor sit amet, consectetur  adipiscing elit, sed diusmod tempor  incididunt ut labore et dolore magna  aliqua.",
      gatsbyImg: data.ss304FoldingRackHeavyImage.childImageSharp.fluid,
    },
  ]
  return (
    <div id="products-card-section">
      <div className="container">
        <div className="product-cards-wrapper">
          {DataArr.map((el, ind) => {
            return (
              <ProductCard
                key={ind}
                productTitle={el.productTitle}
                productDescription={el.productDescription}
                gatsbyImg={el.gatsbyImg}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ProductsCardSection
